import React from 'react';

export const Aim = ({ size = 50, color = 'currentColor' }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Outer circle */}
        <circle fill='transparent' cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
        {/* Horizontal lines */}
        < line x1="12" y1="2" x2="12" y2="6" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <line x1="12" y1="18" x2="12" y2="22" stroke={color} strokeWidth="2" strokeLinecap="round" />
        {/* Vertical lines */}
        <line x1="2" y1="12" x2="6" y2="12" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <line x1="18" y1="12" x2="22" y2="12" stroke={color} strokeWidth="2" strokeLinecap="round" />
        {/* Inner dot */}
        <circle cx="12" cy="12" r="2" fill={color} />
    </svg >);

