import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import { ParallaxText } from "../components/ParallaxText";
import { Section } from "../components/Section";
import { FixedBar } from "../components/FixedBar";
import { Description } from "../components/Description";
import { Companies } from "../components/Companies";
import { Footer } from "../components/Footer";

// eslint-disable-next-line
export const IndexPageTemplate = ({
    image,
    image2,
    isPreview,
    title,
    instagram,
    heading,
    subheading,
    marca, visibilidade,
    carousel,
    future,
}) => {
    const heroImage = getImage(image) || image;
    const aboutImage = getImage(image2) || image2;

    return (
        <div>
            <FullWidthImage img={heroImage} title={title} />
            <FixedBar />
            <section className="section section--gradient" style={{ padding: 0 }}>
                <div>
                    <div>
                        <div className="columns">
                            <div className="">
                                <div className="content">
                                    <div className="about-container">
                                        <div className="">
                                            <h3 className="title ntext">{heading}</h3>
                                            <p className="ntext"> <Description text={subheading} /> </p>
                                        </div>
                                        <div style={{ backgroundImage: `url(${aboutImage.url || aboutImage.images.fallback.src})` }}> </div>
                                    </div>
                                    <ParallaxText>
                                        {carousel}
                                    </ParallaxText>
                                    {!isPreview && (
                                        <>
                                            <Section section={future} />
                                            <Section section={visibilidade} />
                                            <Section section={marca} />
                                        </>
                                    )}
                                    <Companies />
                                    {Boolean(instagram?.cards?.length) && <Footer instagram={instagram} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    description: PropTypes.string,
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
};

const IndexPage = ({ }) => {
    const data = useStaticQuery(pageQuery)
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                image2={frontmatter.image2}
                instagram={data.instagram.frontmatter.instagram}
                title={frontmatter.title}
                heading={frontmatter.heading}
                carousel={frontmatter.carousel}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                future={frontmatter.future}
                marca={frontmatter.marca}
                visibilidade={frontmatter.visibilidade}
                description={frontmatter.description}
                intro={frontmatter.intro}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default IndexPage;

const pageQuery = graphql`
  query IndexPageTemplate {
    instagram: markdownRemark(frontmatter: { templateKey: { eq: "instagram" } }) {
        frontmatter {
            instagram {
                cards {
                    image {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                      }
                    }
                    id
                }

            }
        }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        carousel
        visibilidade {
          title,
          cards {
            title
            description
            button_title
            button_link
            icon
          }
        }
        marca {
          title,
          cards {
            title
            description
            icon
            button_title
            button_link
          }
        }
        future {
          title,
          cards {
            title
            icon
            description
            button_title
            button_link
          }
        }
      }
    }
  }
`;

