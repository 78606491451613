import React from 'react'
import { LogoWall } from './../blocks/Components/LogoWall/LogoWall.jsx'

const Fakini = require("../img/1 -fakini.png")
const Hering = require("../img/2 - hering.png")
const LogHaus = require("../img/3 - loghaus.png")
const Malwee = require("../img/4 - malwee-logo-0.png")
const Texpa = require("../img/5 - texpa.png")
const LaModa = require("../img/6 - la-moda.png")
const Gatomia = require("../img/7---gatomia.png")
const LezALez = require("../img/8 - lez-a-lez.png")
const GrupoKrindges = require("../img/9 - grupo-krindges.png")
const GrupoParaiso = require("../img/10 - grupo-paraiso.png")
const BanaBana = require("../img/11 - bana-bana.png")
const Pituchinhus = require("../img/12 - pituchinhus.png")
const GrupoCistina = require("../img/13 - grupo cristina.png")
const Abrange = require("../img/14 - abrange.png")
const Colisao = require("../img/15 - colisÆo.png")
const LaMandinne = require("../img/16 - la-mandinne.png")
const SlyWear = require("../img/17 - sly-wear.png")
const Svk = require("../img/18 - svk.png")
const Ima = require("../img/ima.png")
const Central = require("../img/central.png")

const items = [
    { imgUrl: Ima.default, altText: "Fakini" },
    { imgUrl: Central.default, altText: "Fakini" },
    { imgUrl: Fakini.default, altText: "Fakini" },
    { imgUrl: Hering.default, altText: "logo" },
    { imgUrl: LogHaus.default, altText: "logo" },
    { imgUrl: Malwee.default, altText: "logo" },
    { imgUrl: Texpa.default, altText: "logo" },
    { imgUrl: LaModa.default, altText: "logo" },
    { imgUrl: Gatomia.default, altText: "logo" },
]

const items1 = [
    { imgUrl: LezALez.default, altText: "logo" },
    { imgUrl: GrupoKrindges.default, altText: "logo" },
    { imgUrl: GrupoParaiso.default, altText: "logo" },
    { imgUrl: BanaBana.default, altText: "logo" },
    { imgUrl: Pituchinhus.default, altText: "logo" },
    { imgUrl: GrupoCistina.default, altText: "logo" },
    { imgUrl: Abrange.default, altText: "logo" },
    { imgUrl: Colisao.default, altText: "logo" },
    { imgUrl: LaMandinne.default, altText: "logo" },
    { imgUrl: SlyWear.default, altText: "logo" },
    { imgUrl: Svk.default, altText: "logo" },
]

export const Companies = () => {
    return (
        <div className='companies'>
            <h3 class="ntitle">
                APENAS ALGUNS DE NOSSOS CLIENTES
            </h3>
            <span class="ntext nblack">
                De novas empresas a marcas consolidadas no mercado,
                <br />
                nossas pesquisas e treinamentos já atingiram +400 empresas de moda no Brasil
            </span>
            <div class="logos">
                <LogoWall size='clamp(7rem, 1rem + 20vmin, 25rem)' pauseOnHover bgAccentColor='var(--white)' bgColor='var(--white)' items={items} />
                <LogoWall size='clamp(7rem, 1rem + 20vmin, 25rem)' pauseOnHover bgAccentColor='var(--white)' bgColor='var(--white)' items={items1} />
            </div>
        </div>


    )
}
