import React from 'react'
export const Icon2 = () => (
    <svg
        version="1.1"
        id="svg1"
        width="669.12134"
        height="645.64667"
        viewBox="0 0 669.12134 645.64667"
        xmlns="http://www.w3.org/2000/svg">
        <defs
            id="defs1">
            <color-profile
                name="sRGB-IEC61966-2.1"
                id="color-profile1" />
            <clipPath
                clipPathUnits="userSpaceOnUse"
                id="clipPath2">
                <path
                    d="M 0,484.235 H 501.841 V 0 H 0 Z"
                    transform="translate(-88.034905,-285.0639)"
                    id="path2" />
            </clipPath>
            <clipPath
                clipPathUnits="userSpaceOnUse"
                id="clipPath4">
                <path
                    d="M 0,484.235 H 501.841 V 0 H 0 Z"
                    transform="translate(-384.68608,-267.9321)"
                    id="path4" />
            </clipPath>
            <clipPath
                clipPathUnits="userSpaceOnUse"
                id="clipPath6">
                <path
                    d="M 0,484.235 H 501.841 V 0 H 0 Z"
                    transform="translate(-117.8185,-235.93271)"
                    id="path6" />
            </clipPath>
            <clipPath
                clipPathUnits="userSpaceOnUse"
                id="clipPath8">
                <path
                    d="M 0,484.235 H 501.841 V 0 H 0 Z"
                    transform="translate(-326.76818,-251.3919)"
                    id="path8" />
            </clipPath>
            <clipPath
                clipPathUnits="userSpaceOnUse"
                id="clipPath10">
                <path
                    d="M 0,484.235 H 501.841 V 0 H 0 Z"
                    transform="translate(-373.24791,-140.0037)"
                    id="path10" />
            </clipPath>
        </defs>
        <g
            id="layer-MC0"
            transform="translate(-689.12134)">
            <path
                id="path1"
                d="m 0,0 c 41.435,-29.798 92.353,-30.941 127.497,-32.137 1.298,-0.044 2.65,-0.501 3.539,-1.448 2.149,-2.292 -0.136,-5.938 -2.45,-8.065 -23.164,-21.293 -57.232,-27.95 -83.939,-43.879 -14.781,-8.816 -29.893,-21.316 -37.706,-36.942 -5.176,-10.353 -12.261,-44.077 14.588,-66.705 15.53,-13.089 37.865,-17.931 58.236,-9.177 60.238,25.888 43.147,96.287 52.326,115.11 7.428,15.229 19.871,15.681 24.156,-0.481 7.871,-29.688 9.808,-66.67 22.315,-94.713 13.289,-29.798 45.688,-52.442 78.203,-55.151 34.574,-2.881 59.034,8.676 73.823,24.764 56.236,61.177 2.254,124.418 -18.773,137.677 -39.79,25.09 -95.437,29.418 -110.775,34.251 -22.642,7.135 43.964,28.055 49.139,29.872 25.956,9.116 56.253,15.64 66.762,47.495 11.294,34.235 -8.946,55.559 -19.059,63.353 -17.346,13.369 -42,15.705 -62.647,3.705 C 205.726,90.378 172.244,12.834 167.156,9.203 162.895,6.162 161.017,12.135 160.064,16.295 154.359,41.189 155.372,83.872 116,123.647 93,146.882 35.912,166.137 -5.647,119.882 -47.294,73.529 -27.647,19.882 0,0"
                style={{ fill: "#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                transform="matrix(1.3333333,0,0,-1.3333333,117.37987,265.56147)"
                clip-path="url(#clipPath2)" />
            <path
                id="path3"
                d="m 0,0 c 14.257,-73.877 -34.075,-145.323 -107.951,-159.58 -73.877,-14.257 -145.324,34.074 -159.581,107.951 -14.257,73.877 34.075,145.323 107.952,159.58 C -85.703,122.208 -14.257,73.877 0,0"
                style={{ fill: "#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                transform="matrix(1.3333333,0,0,-1.3333333,1202.0361,288.40387)"
                clip-path="url(#clipPath4)" />
            <path
                id="path5"
                d="m 0,0 c 64.64,20.276 106.044,61.108 125.402,123.345 2.373,7.629 13.307,7.624 15.655,-0.012 19.247,-62.597 60.915,-103.34 125.11,-123.304 7.758,-2.413 7.763,-13.298 0.006,-15.716 -64.285,-20.038 -105.789,-60.766 -125.09,-123.208 -2.36,-7.634 -13.29,-7.624 -15.655,0.008 C 106.202,-76.834 64.811,-36.03 -0.004,-15.686 -7.714,-13.266 -7.71,-2.418 0,0"
                style={{ fill: "#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                transform="matrix(1.3333333,0,0,-1.3333333,1535.334,331.06973)"
                clip-path="url(#clipPath6)" />
            <path
                id="path7"
                d="m 0,0 -22.093,-21.536 c -3.423,-3.336 -4.985,-8.144 -4.177,-12.856 l 5.215,-30.408 c 2.035,-11.865 -10.418,-20.913 -21.074,-15.311 l -27.309,14.357 c -4.231,2.224 -9.286,2.224 -13.517,0 l -27.309,-14.357 c -10.655,-5.602 -23.109,3.446 -21.074,15.311 l 5.216,30.408 c 0.808,4.712 -0.754,9.52 -4.177,12.856 L -152.393,0 c -8.62,8.403 -3.863,23.043 8.05,24.774 l 30.532,4.436 c 4.731,0.688 8.82,3.659 10.936,7.946 l 13.654,27.667 c 5.328,10.795 20.721,10.795 26.049,0 l 13.655,-27.667 c 2.115,-4.287 6.205,-7.258 10.936,-7.946 L -8.05,24.774 C 3.864,23.043 8.621,8.403 0,0 m -75.848,131.49 c -77.742,0 -140.765,-63.022 -140.765,-140.764 0,-77.743 63.023,-140.765 140.765,-140.765 77.742,0 140.765,63.022 140.765,140.765 0,77.742 -63.023,140.764 -140.765,140.764"
                style={{ fill: "#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                transform="matrix(1.3333333,0,0,-1.3333333,2503.0549,310.45747)"
                clip-path="url(#clipPath8)" />
            <path
                id="path9"
                d="m 0,0 c 43.897,4.829 78.123,42.179 78.044,87.325 -0.082,46.23 -36.106,84.158 -81.528,87.331 -1.476,0.103 -2.962,0.17 -4.457,0.199 -0.622,0.012 -226.47,-0.379 -227.212,-0.381 -2.604,-0.004 -5.183,-0.123 -7.73,-0.351 -44.744,-4.006 -79.896,-41.751 -79.816,-87.503 0.076,-42.971 31.208,-78.771 72.076,-86.15 4.068,-0.735 8.233,-1.188 12.473,-1.339 C -237.053,-0.908 -3.121,-0.343 0,0"
                style={{ fill: "#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                transform="matrix(1.3333333,0,0,-1.3333333,3254.1492,458.97507)"
                clip-path="url(#clipPath10)" />
        </g>
    </svg >
)
