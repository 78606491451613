import React from 'react';

export const Marketing = ({ width = '24', height = '24', color = 'currentColor' }) => {
    return (
        <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512" xmlSpace="preserve">
            <g>
                <g>
                    <path d="M358.614,68.408H332.96c-12.075,0-22.184,8.565-24.579,19.938c-19.694,9.853-116.345,55.616-243.614,72.367
			c-7.147,0.941-13.284,4.825-17.252,10.308H25.119C11.268,171.022,0,182.29,0,196.141v51.307c0,13.851,11.268,25.119,25.119,25.119
			h22.398c3.967,5.483,10.105,9.368,17.251,10.308c1.279,0.169,2.549,0.346,3.823,0.521l39.388,141.798
			c3.009,10.831,12.961,18.396,24.203,18.396h46.829c0.009,0,0.017,0.002,0.026,0.002c0.011,0,0.02-0.002,0.031-0.002h8.524
			c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017h-2.457l-35.771-128.768c84.108,20.495,143.984,48.934,159.018,56.455
			c2.394,11.373,12.504,19.938,24.579,19.938h25.653c13.851,0,25.119-11.268,25.119-25.119V93.527
			C383.733,79.676,372.465,68.408,358.614,68.408z M42.756,256.533H25.119c-5.01,0-9.086-4.076-9.086-9.086v-51.307
			c0-5.01,4.076-9.086,9.086-9.086h17.637V256.533z M168.493,427.556h-36.311c-4.066,0-7.665-2.736-8.753-6.654L85.945,285.971
			c15.784,2.528,31.025,5.47,45.631,8.69L168.493,427.556z M367.699,350.062c0,5.01-4.076,9.086-9.086,9.086H332.96
			c-5.01,0-9.086-4.076-9.086-9.086v-0.01c0-0.018,0-0.036,0-0.055V161.94c0-4.427-3.589-8.017-8.017-8.017
			s-8.017,3.589-8.017,8.017v175.216c-31.563-14.988-123.577-54.724-240.981-70.177c-4.602-0.605-8.071-4.482-8.071-9.017v-72.335
			c0-4.535,3.47-8.411,8.071-9.017c117.404-15.452,209.418-55.189,240.981-70.176v21.301c0,4.427,3.589,8.017,8.017,8.017
			s8.017-3.589,8.017-8.017V93.583c0-0.01,0-0.02,0-0.03v-0.026c0-5.01,4.076-9.086,9.086-9.086h25.653
			c5.01,0,9.086,4.076,9.086,9.086V350.062z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M503.983,213.778h-85.512c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h85.512
			c4.427,0,8.017-3.589,8.017-8.017C512,217.367,508.411,213.778,503.983,213.778z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M485.698,133.125c-1.744-4.07-6.457-5.954-10.526-4.21l-8.552,3.665c-4.069,1.744-5.955,6.457-4.21,10.526
			c1.303,3.04,4.263,4.86,7.372,4.86c1.054,0,2.125-0.209,3.154-0.65l8.552-3.665C485.557,141.907,487.442,137.195,485.698,133.125z
			"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M451.493,147.784c-1.744-4.069-6.457-5.954-10.526-4.21l-25.653,10.995c-4.069,1.744-5.955,6.457-4.21,10.526
			c1.303,3.039,4.263,4.86,7.372,4.86c1.054,0,2.125-0.209,3.154-0.65l25.653-10.995
			C451.352,156.566,453.238,151.853,451.493,147.784z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M481.487,299.937l-8.576-3.675c-4.069-1.745-8.782,0.141-10.526,4.21s0.141,8.782,4.21,10.526l8.576,3.675
			c1.029,0.441,2.1,0.65,3.154,0.65c3.111,0,6.069-1.821,7.372-4.861C487.442,306.394,485.558,301.681,481.487,299.937z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M447.271,285.273l-25.641-10.989c-4.069-1.743-8.783,0.141-10.526,4.21c-1.744,4.07,0.141,8.782,4.21,10.526
			l25.641,10.989c1.029,0.441,2.1,0.65,3.154,0.65c3.111,0,6.069-1.821,7.372-4.86C453.226,291.73,451.34,287.017,447.271,285.273z"
                    />
                </g>
            </g>
        </svg>)
};
