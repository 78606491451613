import React from 'react'
import { Description } from './Description'
import { SpotlightCard } from './../blocks/Components/SpotlightCard/SpotlightCard.jsx'
import { Icon1 } from '../icon1.js'
import { Icon2 } from '../icon2.js'
import { Icon3 } from '../icon3.js'
import { Icon4 } from '../icon4.js'
import { Travel } from '../travel.js'
import { Aim } from '../aim.js'
import { LectureIcon } from '../lecture.js'
import { Trends } from '../trends.js'
import { Marketing } from '../marketing.js'
import { Sales } from '../sales.js'
import { Report } from '../survey.js'
import { School } from '../school.js'
import { Shinny } from '../shinny.js'
import { SalesP } from '../salesP.js'
import { Global } from '../global.js'


const icons = {
    icon1: Icon1, icon2: Icon2,
    icon3: Icon3, icon4: Icon4,
    travel: Travel, aim: Aim,
    lecture: LectureIcon, trends: Trends, marketing: Marketing,
    sales: Sales,
    report: Report,
    school: School,
    shinny: Shinny,
    salesP: SalesP,
    global: Global

}


export const Card = ({ card }) => {
    const Icon = icons[card.icon]
    console.log(Icon)
    return (
        <SpotlightCard spotlightColor=" rgba(13, 40, 254, 0.3)" className='ncard' >
            <div>

                {Icon && <Icon />}
                <div className="ncard-title ntext">
                    {card.title}
                </div>
                <div className="ncard-text ntext">
                    <Description text={card.description} />
                </div>
            </div>
            <div className="ncard-button ntext">
                <a href={card.button_link} target="_blank" rel="noopener noreferrer">
                    <button className='ntext'> {card.button_title} </button>
                </a>
            </div>
        </SpotlightCard>
    )
}
