import React from 'react'

export const Icon1 = () => (
    <svg
        version="1.1"
        id="svg1"
        width="669.12134"
        height="645.64667"
        viewBox="0 0 669.12134 645.64667"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs
            id="defs1">
            <color-profile
                name="sRGB-IEC61966-2.1"
                id="color-profile1" />
            <clipPath
                clipPathUnits="userSpaceOnUse"
                id="clipPath2">
                <path
                    d="M 0,484.235 H 501.841 V 0 H 0 Z"
                    transform="translate(-88.034905,-285.0639)"
                    id="path2" />
            </clipPath>
        </defs>
        <g
            id="layer-MC0">
            <path
                id="path1"
                d="m 0,0 c 41.435,-29.798 92.353,-30.941 127.497,-32.137 1.298,-0.044 2.65,-0.501 3.539,-1.448 2.149,-2.292 -0.136,-5.938 -2.45,-8.065 -23.164,-21.293 -57.232,-27.95 -83.939,-43.879 -14.781,-8.816 -29.893,-21.316 -37.706,-36.942 -5.176,-10.353 -12.261,-44.077 14.588,-66.705 15.53,-13.089 37.865,-17.931 58.236,-9.177 60.238,25.888 43.147,96.287 52.326,115.11 7.428,15.229 19.871,15.681 24.156,-0.481 7.871,-29.688 9.808,-66.67 22.315,-94.713 13.289,-29.798 45.688,-52.442 78.203,-55.151 34.574,-2.881 59.034,8.676 73.823,24.764 56.236,61.177 2.254,124.418 -18.773,137.677 -39.79,25.09 -95.437,29.418 -110.775,34.251 -22.642,7.135 43.964,28.055 49.139,29.872 25.956,9.116 56.253,15.64 66.762,47.495 11.294,34.235 -8.946,55.559 -19.059,63.353 -17.346,13.369 -42,15.705 -62.647,3.705 C 205.726,90.378 172.244,12.834 167.156,9.203 162.895,6.162 161.017,12.135 160.064,16.295 154.359,41.189 155.372,83.872 116,123.647 93,146.882 35.912,166.137 -5.647,119.882 -47.294,73.529 -27.647,19.882 0,0"
                style={{ fill: "#000000 icc-color(sRGB-IEC61966-2, 0.1, 0, 0, 0)", fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                transform="matrix(1.3333333,0,0,-1.3333333,117.37987,265.56147)"
                clip-path="url(#clipPath2)" />
        </g>
    </svg>
)

